import cx from "classnames";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "~/utils";
import { PUSH_ALERT } from "~/pages/PPL/reducers/alerts";
import ContactAdvisorModal from "./contact_advisor_modal";
import s from "./auction_bids_placeholder.module.scss";
import { useVendor } from "app/routes/__vendor-portal+/_new-remix+/ppl+/hooks/useVendor";

const AuctionBidsPlaceholder = () => {
  const {
    vendor: {
      data: { representative },
    },
  } = useVendor();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const email = representative ? representative.email : "";

  return (
    <div className={s["auction-bids-placeholder-wrapper"]}>
      <span className="gdm-icon gdm-icon-xxl gdm-icon-target" />
      <p
        data-testid="auction-bids-placeholder"
        className="gdm-block gdm-paragraph-lg gdm-text-center"
      >
        {t("PPL_EDIT-BIDDING_AUCTION-BIDS-PLACEHOLDER")}
        {email && (
          <Trans
            i18nKey="PPL_EDIT-BIDDING_AUCTION-BIDS_GET-IN-TOUCH-LINK"
            components={{
              contact: (
                <span
                  role="button"
                  tabIndex="0"
                  onKeyUp={() => {}}
                  className={cx("gdm-link-default", s["contact-link"])}
                  onClick={() =>
                    dispatch({
                      type: PUSH_ALERT,
                      component: <ContactAdvisorModal email={email} />,
                    })
                  }
                />
              ),
            }}
          />
        )}
      </p>
    </div>
  );
};

export default AuctionBidsPlaceholder;

const purchaseExpansionLead = async (expansionLeadId) => {
  try {
    const response = await fetch(
      `/ppl/api/expansion_leads/purchase?id=${expansionLeadId}`,
    );
    const {
      expansion_lead,
      error,
      status: errorStatus,
    } = await response.json();

    if (!error) {
      return {
        errors: [],
        data: { expansion_lead },
      };
    }

    return {
      errors: Array.isArray(response.data.errors)
        ? response.data.errors
        : ["Unexpected Error"],
      data: {},
      isInvalid: errorStatus === 400,
    };
  } catch (_err) {
    return {
      errors: ["Unexpected Error"],
      data: {},
    };
  }
};

export default purchaseExpansionLead;

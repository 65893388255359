import {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import fetchNewAccessToken from "../../actions/fetch_new_access_token";
import axios from "../utils/axios";
import { useUserData } from "~/context/UserData";
import { catchError } from "~/utils/catchError";
import { datadogRum } from "@datadog/browser-rum";
import { decodeToken } from "react-jwt";
import { useNavigate } from "@remix-run/react";

// Storing the accessToken globally so axios interceptors use the newest value
// biome-ignore lint/style/useSingleVarDeclarator: <explanation>
let accessToken, ppcCampaignId, ppLcampaignId;

const getAccessToken = () => accessToken;
const getPpcCampaignId = () => ppcCampaignId;
const getPplCampaignId = () => ppLcampaignId;

const CurrentUserContext = createContext({});
const { Provider } = CurrentUserContext;

const isTokenExpired = (accessToken) => {
  if (!accessToken) {
    return true;
  }

  const decodedAccess = decodeToken(accessToken);
  const expires = decodedAccess.exp;

  if (!expires) {
    return true;
  }

  return new Date(expires * 1000) < new Date();
};

const CurrentUserProvider = ({ value, children }) => {
  const {
    setTokens,
    selectResource,
    routeAfterLogin,
    updateProfile,
    selectedPPCCampaign,
    selectedPPLCampaign,
    ...userData
  } = useUserData();

  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [axiosInterceptor, setAxiosInterceptor] = useState({
    request: {},
    response: {},
  });

  const visiblePPCCampaigns = useMemo(() => {
    const campaigns = userData.permissions?.ppc.resources.filter(
      (res) =>
        res.status === "upgraded" ||
        res.status === "paused" ||
        res.status === "draft",
    );
    campaigns?.sort((resource1, resource2) =>
      resource1.name.localeCompare(resource2.name),
    );
    return campaigns ?? [];
  }, [userData.permissions?.ppc]);

  const visiblePPLCampaigns = useMemo(() => {
    const PPLCampaigns = userData.permissions?.ppl.resources;
    PPLCampaigns?.sort((resource1, resource2) =>
      resource1.name.localeCompare(resource2.name),
    );
    return PPLCampaigns ?? [];
  }, [userData.permissions?.ppl]);

  const isRefreshing = useRef(false);
  const pendingRequestList = useRef([]);

  const refreshAccessToken = async (currentRefreshToken) => {
    try {
      const { access_token, refresh_token, id_token } =
        await fetchNewAccessToken(currentRefreshToken);

      setTokens({
        accessToken: access_token,
        refreshToken: refresh_token,
        idToken: id_token,
      });

      accessToken = access_token;
    } catch (error) {
      catchError(error, { reason: "[User Access] Refresh Access Token" });

      return null;
    }
  };

  const addAxiosAccessTokenResponseInterceptor = () =>
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;

        if (
          error.response?.status === 401 &&
          originalRequest.url.startsWith("api/sessions")
        ) {
          navigate("/logout");
          return;
        }

        const isExpired = isTokenExpired(accessToken);

        if (
          error.response?.status === 401 &&
          isExpired &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;

          if (isRefreshing.current === false) {
            // to avoid multiple session calls
            isRefreshing.current = true;

            refreshAccessToken(userData.refreshToken).then(() => {
              isRefreshing.current = false;
              for (const req of pendingRequestList.current) {
                req();
              }
              pendingRequestList.current = [];
            });
          }

          return new Promise((resolve) => {
            pendingRequestList.current = [
              ...pendingRequestList.current,
              () => resolve(axios(originalRequest)),
            ];
          });
        }
        return Promise.reject(error);
      },
    );

  const addAxiosAccessTokenRequestInterceptor = () =>
    axios.interceptors.request.use(
      async (origConfig) => {
        const config = { ...origConfig };

        const { employeeId } = userData;

        if (getAccessToken())
          config.headers.authorization = `Bearer ${getAccessToken()}`;
        if (employeeId) config.headers["X-Employee-Id"] = employeeId;
        if (getPpcCampaignId())
          config.headers["X-PPC-Campaign-Id"] = getPpcCampaignId();
        if (getPplCampaignId())
          config.headers["X-PPL-Campaign-Id"] = getPplCampaignId();

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

  const replaceAxiosInterceptor = () => {
    accessToken = userData.accessToken;

    if (axiosInterceptor.request)
      axios.interceptors.request.eject(axiosInterceptor.request);
    if (axiosInterceptor.response)
      axios.interceptors.response.eject(axiosInterceptor.response);

    const requestInterceptor = addAxiosAccessTokenRequestInterceptor();
    const responseInterceptor = addAxiosAccessTokenResponseInterceptor();

    setAxiosInterceptor({
      request: requestInterceptor,
      response: responseInterceptor,
    });
    if (userData.isLoggedIn) {
      setInitialized(true);
    }
  };

  const updateSelectedPPCCampaignId = (ppc_campaign_id) => {
    ppcCampaignId = ppc_campaign_id;
    datadogRum.setGlobalContextProperty("ppc_campaign_id", ppc_campaign_id);
    replaceAxiosInterceptor();
  };

  const updateSelectedPPLCampaignId = (ppl_campaign_id) => {
    ppLcampaignId = ppl_campaign_id;
    datadogRum.setGlobalContextProperty("ppl_campaign_id", ppl_campaign_id);
    replaceAxiosInterceptor();
  };

  useEffect(() => {
    replaceAxiosInterceptor();
  }, [userData.accessToken, userData.userId]);

  useEffect(() => {
    const ppc_campaign_id = userData.selectedResources?.ppc?.id;
    updateSelectedPPCCampaignId(ppc_campaign_id);
  }, [userData.selectedResources?.ppc]);

  useEffect(() => {
    const ppl_campaign_id = userData.selectedResources?.ppl?.id;
    updateSelectedPPLCampaignId(ppl_campaign_id);
  }, [userData.selectedResources?.ppl]);

  const updateSelectedResource = (service, resourceId) => {
    switch (service) {
      case "ppc":
        updateSelectedPPCCampaignId(resourceId);
        break;
      case "ppl":
        updateSelectedPPLCampaignId(resourceId);
        break;
      default:
        break;
    }

    selectResource(service, resourceId);
  };

  return (
    <Provider
      value={
        value || {
          user: userData,
          selectedPPCCampaign,
          selectedPPLCampaign,
          visiblePPCCampaigns,
          visiblePPLCampaigns,
          updateSelectedResource,
          routeAfterLogin,
          updateProfile,
          initialized,
        }
      }
    >
      {children}
    </Provider>
  );
};

/**
 *
 * @type {() => {user: { email: string, employeeId: string }}}
 */
const useCurrentUser = () => useContext(CurrentUserContext);

export { useCurrentUser, CurrentUserProvider };

CurrentUserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import setChangedBid from "~/pages/PPL/actions/edit_bids/set_changed_bid";
import setSelectedBid from "~/pages/PPL/actions/edit_bids/set_selected_bid";
import getEdidPageData from "~/pages/PPL/selectors/get_edit_page_data";
import fetchSegmentsBids from "~/pages/PPL/actions/fetch_segments";
import { formatCurrency, debounce } from "~/utils";
import NumberFormat from "react-number-format";
import { CLEAR_SELECTED_BID } from "~/pages/PPL/reducers/selected_bid_reducer";
import { CLEAR_AUCTION_BIDS } from "~/pages/PPL/reducers/auction_bids_reducer";
import { DELETE_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { InputField, TableRowAccordion, ToolTip } from "@capterra/arubaito";
import { useTranslation, Trans } from "~/utils";
import TableLoader from "../../../utils/TableLoader";
import s from "./edit_bids_table.module.scss";
import { css } from "ui/css";

const FALLBACK = "-";

const resetButtonCss = css({
  background: "none",
  border: "none",
});

const ellipsedDivCSS = css({
  "white-space": "nowrap",
  "text-overflow": "ellipsis",
  "max-width": "44px",
  "overflow-x": "clip",
});

const filterNullBidRows = (sizeList) =>
  sizeList.filter((srow) => srow.biddingAvailable === true);

const getBidError = (bidAmount, baseCpl, t) =>
  bidAmount > baseCpl
    ? t("PPL_EDIT-BIDDING_ERROR_BID-INCREMENTS-OF-5")
    : t("PPL_EDIT-BIDDING_ERROR_EQUAL-OR-HIGHER-THAN-CPL", { baseCpl });

const returnSegmentBidsRow = (
  segment,
  segmentIndex,
  sizeId,
  sizeKey,
  changedBids,
  openAccordion,
  dispatch,
  productId,
  baseCpl,
  t,
) => {
  const segmentKey = sizeKey + segmentIndex + 1;
  const hasError = changedBids[segmentKey]?.invalid;
  const bidAmount = changedBids[segmentKey]
    ? changedBids[segmentKey].newValues.bidAmount
    : segment.bid;
  const hasSegmentBidChange = !hasError && bidAmount !== segment.bid;

  return (
    <tr key={segment.id}>
      <td className="gdm-w-1" />
      <td className="gdm-w-1 gdm-keep-border gdm-p-none">
        {hasSegmentBidChange && <div className={s["icon-filled-circle"]} />}
      </td>
      <td className="gdm-w-7 gdm-p-left-none gdm-p-right-none gdm-text-left">
        {segment.label}
      </td>
      <td className="gdm-w-4 gdm-allow-overflow">
        <InputField
          className="gdm-m-top-none"
          status={hasError ? "error" : ""}
          render={(id, status) => (
            <>
              <InputField.Input
                id={id}
                status={status}
                name={segment.label}
                autoComplete="off"
                value={bidAmount}
                placeholder={!bidAmount ? `$${baseCpl}` : null}
                onFocus={() => {
                  dispatch(
                    setSelectedBid({
                      type: "segment",
                      changedBidKey: segmentKey,
                      productId,
                      sizeId,
                      id: segment.id,
                    }),
                  );
                }}
                onValueChange={debounce(({ floatValue }) => {
                  if (!bidAmount && !floatValue) return;

                  dispatch(
                    setChangedBid({
                      key: segmentKey,
                      amount: floatValue,
                      baseCpl,
                    }),
                  );
                }, 250)}
                component={<NumberFormat />}
                displayType="input"
                decimalScale={0}
                thousandSeparator
                allowNegative={false}
                prefix="$"
                className="gdm-m-bottom-none gdm-text-center"
                disabled={!openAccordion}
                small
              />
              <InputField.AlertMessage status={status} inline>
                {getBidError(bidAmount, baseCpl, t)}
                <button
                  type="button"
                  tabIndex="0"
                  className={cx("gdm-link-dark gdm-m-left-xs", resetButtonCss)}
                  onClick={() =>
                    dispatch({ type: DELETE_CHANGED_BID, key: segmentKey })
                  }
                  onKeyDown={() =>
                    dispatch({ type: DELETE_CHANGED_BID, key: segmentKey })
                  }
                >
                  Reset Bid
                </button>
              </InputField.AlertMessage>
            </>
          )}
        />
      </td>
      {hasError ? (
        <>
          <td className="gdm-w-2" />
          <td className="gdm-w-3" />
          <td className="gdm-w-3" />
          <td className="gdm-w-3" />
        </>
      ) : (
        <>
          <td className="gdm-w-2">{segment.avgPosition || FALLBACK}</td>
          <td className="gdm-w-3">{FALLBACK}</td>
          <td className="gdm-w-3">{formatCurrency(segment.maxCpl)}</td>
          <td className="gdm-w-3">{FALLBACK}</td>
        </>
      )}
    </tr>
  );
};

const returnSegmentBidRows = (
  segments,
  sizeId,
  sizeKey,
  changedBids,
  open,
  dispatch,
  productId,
  baseCpl,
  t,
) =>
  (segments || []).map((segment, segmentIndex) =>
    returnSegmentBidsRow(
      segment,
      segmentIndex,
      sizeId,
      sizeKey,
      changedBids,
      open,
      dispatch,
      productId,
      baseCpl,
      t,
    ),
  );

const returnAccordionRow = (
  size,
  sizeIndex,
  segments,
  changedBids,
  dispatch,
  productId,
  // biome-ignore lint/style/useDefaultParameterLast: legacy code
  activeProduct = {},
  apiRef,
  sizeState,
  t,
) => {
  const sizeKey = (sizeIndex + 1) * 100;
  const hasError = changedBids[sizeKey]?.invalid;
  const bidAmount = changedBids[sizeKey]
    ? changedBids[sizeKey].newValues.bidAmount
    : size.bidAmount;
  const sizeSegments = segments?.[productId]?.[size.id] || [];
  const rowLength =
    sizeSegments && sizeSegments.length > 0 ? sizeSegments.length + 2 : 3;

  const BidChangeCircle = () => {
    if (!hasError && bidAmount !== size.bidAmount)
      return <div className={s["icon-filled-circle"]} />;

    let hasHollowCircle = false;
    const changedBidKeys = Object.keys(changedBids);
    if (changedBidKeys.length) {
      for (let i = 0; i < changedBidKeys.length; i++) {
        if (
          +changedBidKeys[i] > sizeKey &&
          +changedBidKeys[i] < sizeKey + 100
        ) {
          const pendingBid = changedBids[changedBidKeys[i]];
          if (!pendingBid.invalid) {
            hasHollowCircle = true;
            break;
          }
        }
      }
    }
    return hasHollowCircle ? (
      <div className={s["icon-hollow-circle"]} />
    ) : (
      <></>
    );
  };

  const { sizeDetail, setSizeDetail } = sizeState;
  const onClickToggleButton = (toggle) => {
    toggle();

    const fetchSegments = async () => {
      try {
        apiRef.current[size.id] = true;
        await dispatch(fetchSegmentsBids(productId, [size.id]));
      } catch (_e) {
        setSizeDetail({
          ...sizeDetail,
          [size.id]: { ...sizeDetail[size.id], isFetchError: true },
        });
      } finally {
        delete apiRef.current[size.Id];
      }
    };

    if (!apiRef.current[size.id]) {
      fetchSegments();
    }
  };

  const toggleRowButton = (onToggle) =>
    activeProduct.advancedBiddingAvailable ? (
      <TableRowAccordion.ToggleButton
        className={s["no-outline"]}
        onClick={() => onClickToggleButton(onToggle)}
      >
        {size.label}
      </TableRowAccordion.ToggleButton>
    ) : (
      <span className="gdm-paragraph-sm">{size.label}</span>
    );

  return (
    <TableRowAccordion
      className={s["table-row-accordion"]}
      key={sizeKey}
      rowsLength={rowLength}
      render={(onToggle, open) => (
        <>
          <tr>
            <td className="gdm-w-1 gdm-p-none">
              <BidChangeCircle />
            </td>
            <td className="gdm-w-8 gdm-relative gdm-text-left">
              {toggleRowButton(onToggle)}
            </td>
            <td className="gdm-w-4 gdm-allow-overflow">
              <InputField
                className="gdm-m-top-none"
                status={hasError ? "error" : ""}
                render={(id, status) => (
                  <>
                    <InputField.Input
                      id={id}
                      status={status}
                      name={size.label}
                      autoComplete="off"
                      value={`${bidAmount}`}
                      placeholder={!bidAmount ? `$${size.baseCpl}` : null}
                      onFocus={() => {
                        dispatch(
                          setSelectedBid({
                            type: "size",
                            changedBidKey: sizeKey,
                            id: size.id,
                            productId,
                          }),
                        );
                      }}
                      onValueChange={debounce(({ floatValue }) => {
                        if (!size.bidAmount && !floatValue) return;

                        dispatch(
                          setChangedBid({
                            key: sizeKey,
                            amount: floatValue,
                            baseCpl: size.baseCpl,
                          }),
                        );
                      }, 250)}
                      component={<NumberFormat />}
                      displayType="input"
                      decimalScale={0}
                      thousandSeparator
                      allowNegative={false}
                      prefix="$"
                      className="gdm-m-bottom-none gdm-text-center"
                      small
                    />
                    <InputField.AlertMessage status={status} inline>
                      {getBidError(bidAmount, size.baseCpl, t)}
                      <button
                        type="button"
                        tabIndex="-1"
                        className={cx(
                          "gdm-link-dark gdm-m-left-xs",
                          resetButtonCss,
                        )}
                        onClick={() =>
                          dispatch({ type: DELETE_CHANGED_BID, key: sizeKey })
                        }
                        onKeyDown={() =>
                          dispatch({ type: DELETE_CHANGED_BID, key: sizeKey })
                        }
                      >
                        Reset Bid
                      </button>
                    </InputField.AlertMessage>
                  </>
                )}
              />
            </td>
            {hasError ? (
              <>
                <td className="gdm-w-2 gdm-keep-border" />
                <td className="gdm-w-3 gdm-keep-border" />
                <td className="gdm-w-3 gdm-keep-border" />
                <td className="gdm-w-3 gdm-keep-border" />
              </>
            ) : (
              <>
                <td className="gdm-w-2">{size.avgPosition}</td>
                <td className="gdm-w-3">{formatCurrency(size.baseCpl)}</td>
                <td className="gdm-w-3">{formatCurrency(size.maxCost)}</td>
                <td className="gdm-w-3">{size.recommendationRate}&#37;</td>
              </>
            )}
          </tr>
          <tr>
            <td className="gdm-w-1" />
            <td className="gdm-w-23 gdm-heading-sm gdm-text-left gdm-p-left-none">
              Custom Bids
            </td>
          </tr>
          {sizeSegments.length > 0 ? (
            returnSegmentBidRows(
              sizeSegments,
              size.id,
              sizeKey,
              changedBids,
              open,
              dispatch,
              productId,
              size.baseCpl,
              t,
            )
          ) : (
            <TableLoader
              isError={sizeDetail[size.id] && sizeDetail[size.id].isFetchError}
            />
          )}
        </>
      )}
    />
  );
};

const returnRows = (
  sizes,
  segments,
  changedBids,
  dispatch,
  productId,
  activeProduct,
  apiRef,
  sizeState,
  t,
) =>
  filterNullBidRows(sizes[activeProduct.productId]).map((size, sizeIndex) =>
    returnAccordionRow(
      size,
      sizeIndex,
      segments,
      changedBids,
      dispatch,
      productId,
      activeProduct,
      apiRef,
      sizeState,
      t,
    ),
  );

const Table = ({ productId }) => {
  const [sizeDetail, setSizeDetail] = useState({});
  const dispatch = useDispatch();
  // apiRef will prevent the calling of same API If it is already called
  const apiRef = useRef({});
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_SELECTED_BID });
      dispatch({ type: CLEAR_AUCTION_BIDS });
    };
  }, [dispatch]);

  const { segments, sizes, changedBids, activeProduct } = useSelector((state) =>
    getEdidPageData(state, productId),
  );

  if (!Object.keys(sizes).length || !activeProduct) return null;

  return (
    <table
      className={cx(
        "gdm-table gdm-table-reset gdm-table-inputs gdm-text-center gdm-w-24",
        s["edit-bids-table"],
      )}
    >
      <thead>
        <tr>
          <th className="gdm-w-1 gdm-keep-border gdm-p-none" />
          <th className="gdm-w-8 gdm-text-left">
            {/* TODO:: check to optimize */}
            {t("PPL_EDIT-BIDDING_TABLE-HEADER_SIZE-BAND")}
            {activeProduct.advancedBiddingAvailable &&
              t("PPL_EDIT-BIDDING_TABLE-HEADER_CUSTOM-SIZE-BAND")}
          </th>
          <th className="gdm-w-4">
            <ToolTip
              targetId="ppl-anchor"
              placement="top"
              trigger={
                <div>
                  <span className="gdm-icon gdm-icon-sm gdm-icon-info" />
                  <Trans i18nKey="PPL_EDIT-BIDDING_TABLE-HEADER_DEFAULT-BID" />
                </div>
              }
            >
              {t("PPL_EDIT-BIDDING_TABLE-HEADER-TOOLTIP_DEFAULT-BID")}
            </ToolTip>
          </th>
          <th className="gdm-w-2">
            <ToolTip
              targetId="ppl-anchor"
              placement="top"
              trigger={
                <div
                  className={ellipsedDivCSS}
                  title={t("PPL_EDIT-BIDDING_TABLE-HEADER_AVG-POS")}
                >
                  <span className="gdm-icon gdm-icon-sm gdm-icon-info" />
                  {t("PPL_EDIT-BIDDING_TABLE-HEADER_AVG-POS")}
                </div>
              }
            >
              {t("PPL_EDIT-BIDDING_TABLE-HEADER-TOOLTIP_AVG-POS")}
            </ToolTip>
          </th>
          <th className="gdm-w-3">
            <ToolTip
              targetId="ppl-anchor"
              placement="top"
              trigger={
                <div>
                  <span className="gdm-icon gdm-icon-sm gdm-icon-info" />
                  {t("PPL_EDIT-BIDDING_TABLE-HEADER_BASE_CPL")}
                </div>
              }
            >
              {t("PPL_EDIT-BIDDING_TABLE-HEADER-TOOLTIP_BASE_CPL")}
            </ToolTip>
          </th>
          <th className="gdm-w-3">
            <ToolTip
              targetId="ppl-anchor"
              placement="top"
              trigger={
                <div>
                  <span className="gdm-icon gdm-icon-sm gdm-icon-info" />
                  {t("PPL_EDIT-BIDDING_TABLE-HEADER_MAX-COST")}
                </div>
              }
            >
              {t("PPL_EDIT-BIDDING_TABLE-HEADER-TOOLTIP_MAX-COST")}
            </ToolTip>
          </th>
          <th className="gdm-w-3">
            <ToolTip
              targetId="ppl-anchor"
              placement="top"
              trigger={
                <div>
                  <span className="gdm-icon gdm-icon-sm gdm-icon-info" />
                  {t("PPL_EDIT-BIDDING_TABLE-HEADER-REC-RATE")}
                </div>
              }
            >
              {t("PPL_EDIT-BIDDING_TABLE-HEADER-TOOLTIP_REC-RATE")}
            </ToolTip>
          </th>
        </tr>
      </thead>
      {returnRows(
        sizes,
        segments,
        changedBids,
        dispatch,
        productId,
        activeProduct,
        apiRef,
        { sizeDetail, setSizeDetail },
        t,
      )}
    </table>
  );
};

Table.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default Table;

import { useState, useEffect } from "react";
import { useNavigate } from "@remix-run/react";
import RemoveModal from "./RemoveModal";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { formatDate } from "~/pages/PPL/utils/date";
import fetchExpansionLeads from "~/pages/PPL/actions/expansion_leads/fetch_expansion_leads";
import deleteExpansionLead from "~/pages/PPL/actions/expansion_leads/delete_expansion_lead";
import { useTranslation } from "~/utils";
import { Inline, Text, Table, type TableType } from "~/components/vendorUI";
import { Anchor, anchorCSS } from "~/components/Text";
import {
  PaginationFooter,
  usePaginationFooter,
} from "~/components/PaginationFooter";
import { css, cx } from "ui/css";

type ExpansionLeadType = {
  amount: string;
  company_size: string;
  buyer_signed_up_at: string;
  id: number;
  lead_id: Nullable<number>;
  market_id: number;
  market_name: string;
  product_id: number;
  product_name: string;
  industry_name: string;
  spots_left: number;
  status: string;
};

const redAnchorCSS = css({ color: "red" });

const FALLBACK = "-";
const DECIMAL_PLACES = 2;

const ExpansionLeadsTable = () => {
  const { t } = useTranslation();
  const { selectedPPLCampaign } = useCurrentUser();
  const [expansionLeads, setExpansionLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState({
    isOpen: false,
    isError: false,
    body: "",
  });

  const toggleModal = (body: string, isError = false) =>
    setRemoveModalOpen((s) => ({ isOpen: !s.isOpen, body, isError }));

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await fetchExpansionLeads();
      setExpansionLeads(res.expansion_leads);
      setIsLoading(false);
    };
    fetchData();
  }, [selectedPPLCampaign]);

  const navigate = useNavigate();

  const getLeadAmount = (leadAmount: string) => {
    if (!leadAmount) return FALLBACK;
    return Number(leadAmount).toFixed(DECIMAL_PLACES);
  };

  const handleDeleteExpansionLead = async (id: number) => {
    setIsLoading(true);
    const res = await deleteExpansionLead(id);

    if (res.data?.expansion_lead?.id) {
      toggleModal(t("PPL_EXPANSION-LEADS_MODAL_REMOVE-SUCCESS"));
      setExpansionLeads((leads) =>
        leads.filter(
          ({ id }) => String(id) !== String(res.data?.expansion_lead?.id),
        ),
      );
    } else {
      toggleModal(t("PPL_EXPANSION-LEADS_MODAL_REMOVE-ERROR"), true);
    }
    setIsLoading(false);
  };

  const handlePurchaseExpansionLead = (id: number) => {
    if (!isLoading) navigate(`/ppl/expansionleads/${id}/purchase`);
  };

  const tableColumns: TableType<ExpansionLeadType>["columns"] = [
    {
      accessorKey: "buyer_signed_up_at",
      content: t("PPL_EXPANSION-LEADS_TABLE_HEADER_QUAL-DATE"),
      cellAlign: "right",
      cell: ({ getValue }) => {
        return <Inline align="center">{formatDate(getValue())}</Inline>;
      },
    },
    {
      accessorKey: "market_name",
      content: t("PPL_EXPANSION-LEADS_TABLE_HEADER_MARKET"),
      cellAlign: "center",
      cell: ({ getValue, row: { original } }) => (
        <Anchor
          href={`/ppl/expansionleads/${isLoading ? "" : original.id}`}
          data-tracking="ppl_expansion_leads_table_market"
        >
          {(getValue() as string) || FALLBACK}
        </Anchor>
      ),
    },
    {
      accessorKey: "product_name",
      content: t("PPL_EXPANSION-LEADS_TABLE_HEADER_RECOMMENDED-PRODUCT"),
      cell: ({ getValue }) => getValue() || FALLBACK,
    },
    {
      accessorKey: "company_size",
      content: t("SIZE"),
      cell: ({ getValue }) => getValue() || FALLBACK,
    },
    {
      accessorKey: "industry_name",
      content: t("PPL_EXPANSION-LEADS_TABLE_HEADER_INDUSTRY"),
      cell: ({ getValue }) => getValue() || FALLBACK,
    },
    {
      accessorKey: "spots_left",
      content: t("PPL_EXPANSION-LEADS_TABLE_HEADER_SPOTS-LEFT"),
      cellAlign: "right",
      cell: ({ getValue }) => getValue() || FALLBACK,
    },
    {
      accessorKey: "amount",
      content: t("PRICE"),
      cellAlign: "right",
      cell: ({ getValue }) => (
        <>${getLeadAmount(getValue() as string) || FALLBACK}</>
      ),
    },
    {
      accessorKey: " ",
      cellAlign: "center",
      content: "",
      cell: ({ row: { original } }) => {
        return (
          <Inline gap="24px">
            <button
              type="button"
              className={anchorCSS()}
              onClick={() => handlePurchaseExpansionLead(original.id)}
              data-tracking="ppl_expansion_leads_table_purchase"
            >
              {t("PPL_EXPANSION-LEADS_ACTION_PURCHASE")}
            </button>
            <button
              type="button"
              className={cx(anchorCSS(), redAnchorCSS)}
              onClick={() => handleDeleteExpansionLead(original.id)}
              data-tracking="ppl_expansion_leads_table_delete"
            >
              {t("PPC-UPGRADE_STRIPE_MODAL_DELETE-CARD")}
            </button>
          </Inline>
        );
      },
    },
  ];

  const { compProps, getVisibleList } = usePaginationFooter({
    totalItems: expansionLeads.length,
  });

  return (
    <>
      <Table
        columns={tableColumns}
        loading={isLoading}
        data={getVisibleList(expansionLeads)}
        noDataJsx={
          <Text
            size="14px"
            weight="medium"
            color="brand"
            className={css({ paddingBlock: "8px" })}
          >
            {t("PPL_EXPANSION-LEADS_TABLE_EMPTY-ERROR")}
          </Text>
        }
      />
      <RemoveModal
        isOpen={removeModalOpen.isOpen}
        isError={removeModalOpen.isError}
        body={removeModalOpen.body}
        handleClose={() => toggleModal("")}
      />
      {expansionLeads.length > compProps.pageSize && (
        <PaginationFooter totalItems={expansionLeads.length} {...compProps} />
      )}
    </>
  );
};

export default ExpansionLeadsTable;

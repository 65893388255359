/* istanbul ignore file */
import { useNavigate } from "@remix-run/react";
import { Suspense, lazy, useEffect } from "react";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { LoadingOverlay } from "~/components";
import { useUserData } from "~/context/UserData";
import { useFeatureFlags } from "~/hooks/featureFlags";
const UpgradedClickReport = lazy(() => import("./ClickReport"));
const BasicClickReport = lazy(() => import("./BasicClickReport"));

export const ClickReport = () => {
  const { selectedPPCCampaign } = useCurrentUser();
  const { campaignSummary } = useUserData();
  const { isFeatureEnabled } = useFeatureFlags();
  const navigate = useNavigate();

  useEffect(() => {
    if (isFeatureEnabled("click-report-remix-migration")) {
      return navigate("/ppc/clicks");
    }
  }, []);

  if (!campaignSummary) return <LoadingOverlay />;

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {selectedPPCCampaign?.status === "upgraded" ||
      selectedPPCCampaign?.status === "paused" ||
      campaignSummary.formerlyUpgraded ? (
        <UpgradedClickReport />
      ) : (
        <BasicClickReport />
      )}
    </Suspense>
  );
};

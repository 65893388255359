import { useMemo } from "react";
import { useLocation as useRemixLocation } from "@remix-run/react";
import { useLocation } from "react-router-dom";
import { css } from "ui/css";
import { Stack } from "~/components/vendorUI";
import { FlashMessage } from "~/components";
import Routes from "~/app/components/routes";
import { Breadcrumbs } from "~/app/components/breadcrumb";
import CampaignSwitcher from "~/app/components/campaign_switcher";
import Tracking from "~/components/Tracking";
import { useCurrentCampaign } from "~/context/CurrentCampaignContext";

const pageWrapperCSS = css({
  flexGrow: 1,
});

const App = () => {
  const { keyLocation, showSubheader } = useShowSubheader();
  const { switcherConfig } = useCurrentCampaign();

  return (
    <Stack gap="48px">
      {showSubheader ? (
        <Stack
          align="start"
          gap="48px"
          className={css({
            marginBlockStart: "calc(token(spacing.24px) * -1)",
          })}
        >
          <Breadcrumbs />
          {switcherConfig.enabled ? <CampaignSwitcher /> : null}
          <FlashMessage />
        </Stack>
      ) : null}
      <main className={pageWrapperCSS}>
        <Routes key={keyLocation} />
      </main>
      <Tracking />
    </Stack>
  );
};

const routesWithRemixCampaignSwitcher = [
  "/ppc/manage-bids",
  "/ppc/manage-bids/playground",
  "/ppc/manage-bids/autobidder",
  "/ppl",
];

function useShowSubheader() {
  const location = useLocation();

  const remixLocation = useRemixLocation();
  const showSubheader = !(
    routesWithRemixCampaignSwitcher.some((e) =>
      location.pathname.includes(e),
    ) ||
    routesWithRemixCampaignSwitcher.includes((e) =>
      remixLocation.pathname.includes(e),
    )
  );

  const pageType = useMemo(() => {
    const { pathname: remixPath } = remixLocation;
    const { pathname } = location;

    if (pathname.includes("/reviews") || remixPath.includes("/reviews")) {
      return "reviews";
    }

    return "default";
  }, [location, remixLocation]);

  // in case of a revalidation to avoid obsolete data and to retrigger the loader of the remix compliant routes we must force the change using the location key
  return {
    keyLocation: remixLocation.key,
    showSubheader,
    pageType,
  };
}

export default App;

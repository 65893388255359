import cx from "classnames";
import PropTypes from "prop-types";
import { ToolTip } from "@capterra/arubaito";
import s from "../connected/pre_purchase.module.scss";
import { useTranslation } from "~/utils";

const LeadColData = ({ colData = [], lead = {}, colWidth = 8 }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(`gdm-col gdm-col-${colWidth} gdm-m-right-xl`, s.colData)}
    >
      {colData.map(
        ({
          title,
          key,
          toolTip,
          toolTipKey,
          nested,
          getNestedValue,
          value,
        }) => (
          <div key={title}>
            {toolTip ? (
              <div>
                <div id="leadDetailsTitle" className="gdm-m-top-xs" />
                <span className="gdm-heading-sm">{t(title)}</span>
                <ToolTip
                  targetId="leadDetailsTitle"
                  placement="top"
                  trigger={
                    <span
                      className={cx(
                        "gdm-icon gdm-icon-info gdm-m-top-sm gdm-m-left-xxs",
                        s["icon-custom-size"],
                      )}
                    />
                  }
                >
                  {t(toolTipKey) || `[${t(title)}]`}
                </ToolTip>
              </div>
            ) : (
              <span className="gdm-heading-sm gdm-block">{t(title)}</span>
            )}
            {nested ? (
              <span className="gdm-paragraph-sm">
                {getNestedValue(lead) || `[${t(title)}]`}
              </span>
            ) : (
              <span className="gdm-paragraph-sm">
                {value || lead[key] || `[${t(title)}]`}
              </span>
            )}
          </div>
        ),
      )}
    </div>
  );
};

LeadColData.propTypes = {
  //   Set of Keys diplayed in 1 column
  colData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      toolTip: PropTypes.bool,
      toolTipKey: PropTypes.string,
      nested: PropTypes.bool,
      getNestedValue: PropTypes.func,
    }),
  ),
  // set of values coming from API call
  lead: PropTypes.object,
  // column width
  colWidth: PropTypes.number,
};

export default LeadColData;

import fetchMarkets from "../fetch_markets";
import fetchProducts from "../fetch_products";
import fetchProductsSizes from "../fetch_products_sizes";
import setIsLoading from "../set_is_loading";

const fetchEditBidsData = (productId) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const [sizeBand] = (await dispatch(fetchProductsSizes([productId]))) || [];
    if (!sizeBand) return null;

    await dispatch(fetchProducts());
    await dispatch(fetchMarkets());

    return true;
  } catch (_err) {
    return null;
  } finally {
    dispatch(setIsLoading(false));
  }
};

export default fetchEditBidsData;

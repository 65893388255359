import { useEffect, useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { AlertMessage, InputField } from "@capterra/arubaito";
import {
  isCurrentPasswordInvalid,
  hasNewPasswordSpaces,
  isNewPasswordInvalid,
  isPasswordSame,
  isPasswordMismatched,
} from "../utils/my_profile_utils";
import { SET_ERROR } from "../reducers/my_profile_reducer";
import { LABELS, MESSAGES, PLACEHOLDERS, TITLES } from "../config/copy";
import { FIELD_NAMES, ERROR_ALERT_TYPE } from "../config/constants";
import { useTranslation, Trans } from "~/utils";
import mStyles from "../MyProfile.module.scss";
import { InfoTooltip } from "~/components/vendorUI";
import { css } from "ui/css";

const PasswordForm = ({
  currentPassword,
  newPassword,
  confirmPassword,
  handleChange,
  hasError,
  handleBlur,
  dispatch,
}) => {
  const { t } = useTranslation();

  const isRequired = useMemo(
    () =>
      newPassword.length > 0 ||
      currentPassword.length > 0 ||
      confirmPassword.length > 0,
    [newPassword, currentPassword],
  );

  const isNewPasswordSame = useMemo(
    () =>
      newPassword.length > 0 &&
      currentPassword.length > 0 &&
      newPassword === currentPassword,
    [newPassword, currentPassword],
  );

  useEffect(() => {
    if (
      newPassword.length > 0 ||
      currentPassword.length > 0 ||
      confirmPassword.length > 0
    ) {
      if (confirmPassword.length === 0)
        dispatch({
          type: SET_ERROR,
          field: FIELD_NAMES.confirmPassword,
          error: true,
        });
      if (currentPassword.length === 0)
        dispatch({
          type: SET_ERROR,
          field: FIELD_NAMES.currentPassword,
          error: true,
        });
      if (newPassword.length === 0 || isNewPasswordSame)
        dispatch({
          type: SET_ERROR,
          field: FIELD_NAMES.newPassword,
          error: true,
        });
    } else {
      dispatch({
        type: SET_ERROR,
        field: FIELD_NAMES.newPassword,
        error: false,
      });
      dispatch({
        type: SET_ERROR,
        field: FIELD_NAMES.confirmPassword,
        error: false,
      });
      dispatch({
        type: SET_ERROR,
        field: FIELD_NAMES.currentPassword,
        error: false,
      });
    }
  }, [newPassword, currentPassword, confirmPassword]);

  const getAlertMessage = (formKey, value) => {
    if (isCurrentPasswordInvalid(formKey, value))
      return t(MESSAGES.invalidPassword);
    if (hasNewPasswordSpaces(formKey, value))
      return t(MESSAGES.invalidPasswordSpaces);
    if (isNewPasswordInvalid(formKey, value))
      return t(MESSAGES.invalidPassword);
    if (isPasswordSame(formKey, currentPassword, newPassword))
      return t(MESSAGES.samePassword);
    if (isPasswordMismatched(formKey, value, confirmPassword, newPassword))
      return t(MESSAGES.mismatchedPassword);

    if (hasError(formKey)) {
      if (isPasswordSame(formKey, currentPassword, newPassword))
        return t(MESSAGES.samePassword);
      return t("MY-PROFILE_ALERT_IS-REQUIRED", {
        labelName: t(LABELS[formKey]),
      });
    }
  };

  const getPasswordAlert = (formKey, value) => {
    const alertMessage = getAlertMessage(formKey, value);

    return alertMessage ? (
      <AlertMessage className="gdm-m-top-xs" status={ERROR_ALERT_TYPE}>
        {alertMessage}
      </AlertMessage>
    ) : (
      <div className={mStyles.error_placeholder} />
    );
  };

  return (
    <div className="gdm-m-top-xl">
      <p className="gdm-heading-lg gdm-m-bottom-md">
        {t(TITLES.changePassword)}
      </p>
      <div>
        <InputField
          className={cx("gdm-w-6", mStyles.password_input)}
          render={(id, status) => (
            <>
              <InputField.Label htmlFor={id}>
                {t(LABELS.currentPassword)}
                {isRequired && (
                  <span className="gdm-icon gdm-icon-xs gdm-icon-required gdm-m-left-xxs" />
                )}
              </InputField.Label>
              <InputField.Input
                status={status}
                id={id}
                value={currentPassword}
                name={FIELD_NAMES.currentPassword}
                type="password"
                placeholder={t(PLACEHOLDERS.currentPassword)}
                onChange={handleChange}
              />
              {getPasswordAlert(FIELD_NAMES.currentPassword, currentPassword)}
            </>
          )}
        />
      </div>
      <div className="gdm-m-bottom-xs">
        <InputField
          className={cx("gdm-w-6", mStyles.password_input)}
          render={(id, status) => (
            <>
              <InputField.Label
                htmlFor={id}
                className={css({ display: "flex !", gap: "4px" })}
              >
                <span className="gdm-label">{t(LABELS.newPassword)}</span>
                {isRequired && (
                  <span className="gdm-icon gdm-icon-xs gdm-icon-required gdm-m-left-xxs" />
                )}
                <div id="newPasswordTooltip" />
                <InfoTooltip targetId="newPasswordTooltip">
                  <Trans
                    i18nKey="MY-PROFILE_PASSWORD-TOOLTIP_MESSAGE"
                    components={{
                      li: (
                        <li
                          className={css({
                            listStyleType: "disc",
                          })}
                        />
                      ),
                      ul: <ul />,
                      ul2: (
                        <ul className={css({ paddingInlineStart: "15px" })} />
                      ),
                    }}
                  />
                </InfoTooltip>
              </InputField.Label>
              <InputField.Input
                status={status}
                id={id}
                value={newPassword}
                name={FIELD_NAMES.newPassword}
                type="password"
                placeholder={t(PLACEHOLDERS.newPassword)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {getPasswordAlert(FIELD_NAMES.newPassword, newPassword)}
            </>
          )}
        />
      </div>
      <div className="gdm-m-bottom-lg">
        <InputField
          className={cx("gdm-w-6", mStyles.password_input)}
          render={(id, status) => (
            <>
              <InputField.Label htmlFor={id}>
                {t(LABELS.confirmPassword)}
                {isRequired && (
                  <span className="gdm-icon gdm-icon-xs gdm-icon-required gdm-m-left-xxs" />
                )}
              </InputField.Label>
              <InputField.Input
                className="gdm-m-bottom-xxs"
                status={status}
                id={id}
                value={confirmPassword}
                name={FIELD_NAMES.confirmPassword}
                type="password"
                placeholder={t(PLACEHOLDERS.confirmPassword)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {getPasswordAlert(FIELD_NAMES.confirmPassword, confirmPassword)}
            </>
          )}
        />
      </div>
    </div>
  );
};

PasswordForm.propTypes = {
  currentPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  hasError: PropTypes.func,
  errorKeys: PropTypes.array,
  dispatch: PropTypes.func,
};

export default PasswordForm;

import { Fragment, useMemo } from "react";
import { StatesDropdown } from "~/components/StatesDropdown";
import { COUNTRIES } from "../../assets/static";
import { InputField, List } from "@capterra/arubaito";
import { isValidZip, isEmpty, isValidAddress, isValidCity } from "../../utils";
import { useTranslation } from "~/utils";
import { Columns } from "~/components/vendorUI";

const BillingAddress = ({
  id,
  state,
  handleChange,
  touched,
  handleTouched,
  errors,
  handleErrors,
}) => {
  const { t } = useTranslation();

  const selectedCountry = useMemo(() => {
    const fallbackState = state.country === "UK" ? "GB" : state.country;
    const found = COUNTRIES.find(({ code }) => code === fallbackState);
    if (found && Array.isArray(found.states))
      return { label: found.name, value: found.code, states: found.states };
    if (found) return { label: found.name, value: found.code };
    return null;
  }, [state.country]);

  return (
    <form>
      <Columns>
        <Columns.Column span={id === "PPL" ? 7 : 15} offsetEnd={1}>
          <InputField
            status={touched.address && errors.address ? "error" : ""}
            render={(id, status) => (
              <Fragment>
                <InputField.Label htmlFor={id}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_ADDRESS") + " "}
                  <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
                </InputField.Label>
                <InputField.Input
                  status={status}
                  id={id}
                  value={state.address}
                  type="text"
                  name="address"
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                    handleErrors("address", !isValidAddress(e.target.value));
                    handleTouched("address", true);
                  }}
                  onBlur={() => {
                    handleChange("address", state.address.trim());
                    handleErrors("address", !isValidAddress(state.address));
                    handleTouched("address", true);
                  }}
                />
                <InputField.AlertMessage status={status}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_ADDRESS")}
                </InputField.AlertMessage>
              </Fragment>
            )}
          />
        </Columns.Column>
        {id === "PPL" && (
          <Columns.Column span={7}>
            <InputField
              status={touched.address2 && errors.address2 ? "error" : ""}
              render={(id, status) => (
                <Fragment>
                  <InputField.Label htmlFor={id}>
                    {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_ADDRESS2")}
                  </InputField.Label>
                  <InputField.Input
                    status={status}
                    id={id}
                    value={state.address2}
                    type="text"
                    name="address2"
                    onChange={(e) => {
                      handleChange(e.target.name, e.target.value);
                      handleErrors(
                        "address2",
                        !(
                          isEmpty(e.target.value) ||
                          isValidAddress(e.target.value)
                        ),
                      );
                      handleTouched("address2", true);
                    }}
                    onBlur={() => {
                      handleChange("address2", state.address2.trim());
                      handleErrors(
                        "address2",
                        !(
                          isEmpty(state.address2) ||
                          isValidAddress(state.address2)
                        ),
                      );
                      handleTouched("address2", true);
                    }}
                  />
                  <InputField.AlertMessage status={status}>
                    {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_ADDRESS2")}
                  </InputField.AlertMessage>
                </Fragment>
              )}
            />
          </Columns.Column>
        )}
      </Columns>

      <Columns>
        <Columns.Column span={7} offsetEnd={1}>
          <InputField
            status={touched.city && errors.city ? "error" : ""}
            render={(id, status) => (
              <Fragment>
                <InputField.Label htmlFor={id}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_CITY") + " "}
                  <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
                </InputField.Label>
                <InputField.Input
                  status={status}
                  id={id}
                  value={state.city}
                  type="text"
                  name="city"
                  onChange={(e) => {
                    handleChange(
                      e.target.name,
                      String(e.target.value).replace(/[^A-Za-z\s-_,]/g, ""),
                    );
                    handleErrors("city", !isValidCity(e.target.value));
                    handleTouched("city", true);
                  }}
                  onBlur={() => {
                    handleChange("city", state.city.trim());
                    handleErrors("city", !isValidCity(state.city));
                    handleTouched("city", true);
                  }}
                />
                <InputField.AlertMessage status={status}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_CITY")}
                </InputField.AlertMessage>
              </Fragment>
            )}
          />
        </Columns.Column>
        <Columns.Column span={7}>
          <InputField
            status={touched.zip_code && errors.zip_code ? "error" : ""}
            render={(id, status) => (
              <Fragment>
                <InputField.Label htmlFor={id}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_ZIPCODE") + " "}
                  <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
                </InputField.Label>
                <InputField.Input
                  status={status}
                  id={id}
                  value={state.zip_code}
                  type="text"
                  name="zip_code"
                  onChange={(e) => {
                    handleChange(
                      e.target.name,
                      String(e.target.value)
                        .replace(/[^A-Za-z0-9\s]/g, "")
                        .toUpperCase()
                        .slice(0, 14),
                    );
                    handleErrors(
                      "zip_code",
                      !isValidZip(
                        String(e.target.value)
                          .replace(/[^A-Za-z0-9\s]/g, "")
                          .toUpperCase()
                          .slice(0, 14),
                      ),
                    );
                    handleTouched("zip_code", true);
                  }}
                  onBlur={() => {
                    handleChange("zip_code", state.zip_code.trim());
                    handleErrors(
                      "zip_code",
                      !isValidZip(state.zip_code.trim()),
                    );
                    handleTouched("zip_code", true);
                  }}
                />
                <InputField.AlertMessage status={status}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_ZIPCODE")}
                </InputField.AlertMessage>
              </Fragment>
            )}
          />
        </Columns.Column>
      </Columns>

      <Columns>
        <Columns.Column span={7} offsetEnd={1}>
          {["US", "IN"].includes(selectedCountry?.value) && (
            <InputField
              status={touched.state && errors.state ? "error" : ""}
              render={(elId, status) => (
                <Fragment>
                  <InputField.Label htmlFor={elId}>
                    {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_STATE") + " "}
                    <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
                  </InputField.Label>
                  <StatesDropdown
                    id={elId}
                    className="gdm-m-bottom-md"
                    countryCode={selectedCountry?.value}
                    selectedStateCode={state.state}
                    includeCountryPrefix={id !== "PPL"}
                    onChange={(obj) => {
                      handleChange("state", obj?.value);
                      handleTouched("state", true);
                      handleErrors("state", !obj);
                    }}
                  />
                  <InputField.AlertMessage status={status}>
                    {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_STATE")}
                  </InputField.AlertMessage>
                </Fragment>
              )}
            />
          )}
        </Columns.Column>
        <Columns.Column span={7}>
          <InputField
            status={touched.country && errors.country ? "error" : ""}
            render={(id, status) => (
              <Fragment>
                <InputField.Label htmlFor={id}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_LABEL_COUNTRY") + " "}
                  <span className="gdm-icon gdm-icon-xs gdm-icon-required" />
                </InputField.Label>
                <List
                  defaultValue={null}
                  isClearable
                  className="gdm-m-bottom-md"
                  onChange={(obj) => {
                    handleChange("country", obj?.value);
                    handleChange("state", null);
                    handleTouched("country", true);
                    handleErrors("country", !obj);
                  }}
                  value={selectedCountry}
                  options={COUNTRIES.map(({ name, code }) => ({
                    value: code === "GB" ? "UK" : code,
                    label: name,
                  }))}
                />
                <InputField.AlertMessage status={status}>
                  {t("ACCOUNT-SETTINGS_BILLING-FORM_ERROR_COUNTRY")}
                </InputField.AlertMessage>
              </Fragment>
            )}
          />
        </Columns.Column>
      </Columns>
    </form>
  );
};

export default BillingAddress;

import { Suspense, lazy, memo, useMemo } from "react";
import { Navigate, useLocation } from "@remix-run/react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { lazily } from "react-lazily";
import { LoadingOverlay } from "~/components";

// pages under src/pages
import { PPL } from "~/pages/PPL";
import { PPCPaymentDetails } from "~/pages/PPCPaymentDetails";
import { ClickReport } from "~/pages/ClickReport";
import { MyProfile } from "~/pages/MyProfile";
import { PrivatePPLRoute } from "./private_ppl_route";
import { PrivateRoute } from "./private_route";
import { Route } from "./route";
import { useCurrentUser } from "~/app/shared/contexts/currentUserContext";
import { useCurrentUser as useCurrentUserRemix } from "app/hooks/useCurrentUser";
import { RenderIf } from "~/components";
import { path } from "./path";
import { useFeatureFlags as useFFlags } from "~/hooks/featureFlags";

const { Page404 } = lazily(() => import("~/routes/404/index"));

const PPLPaymentInfo = lazy(() => import("~/pages/PPLPaymentInfo"));

const { PPLRefunds } = lazily(() => import("~/pages/PPLRefunds"));

const { PPC } = lazily(() => import("~/routes/ppc"));
const { PPL: RemixPPL } = lazily(() => import("~/routes/ppl"));

export default function RoutesContainer() {
  const history = useHistory();
  const { user, selectedPPLCampaign, selectedPPCCampaign } = useCurrentUser();
  const { selectedProductId } = useCurrentUserRemix();
  const { permissions } = user;
  const { isFeatureEnabled: isFFEnabled } = useFFlags();

  const userProfile = useMemo(() => {
    return {
      email: user?.email,
      employeeId: user?.employeeId,
      profile: {
        firstName: user?.profile?.firstName,
        lastName: user?.profile?.lastName,
        timeZone: user?.profile?.timeZone,
      },
      isLoading: user?.isLoading,
      isLoggedIn: user?.isLoggedIn,
    };
  }, [
    user?.email,
    user?.employeeId,
    user?.profile?.firstName,
    user?.profile?.lastName,
    user?.profile?.timeZone,
    user?.isLoading,
    user?.isLoggedIn,
  ]);

  return (
    <Routes
      history={history}
      user={userProfile}
      selectedPPCCampaign={selectedPPCCampaign}
      selectedPPLCampaign={selectedPPLCampaign}
      selectedProductId={selectedProductId}
      permissions={permissions}
      isFFEnabled={isFFEnabled}
    />
  );
}

const Routes = memo(
  ({
    history,
    user,
    selectedPPCCampaign,
    selectedPPLCampaign,
    selectedProductId,
    permissions,
  }) => {
    const location = useLocation();
    const { isFeatureEnabled: isFFEnabled } = useFFlags();
    const isNewPPLPageEnabled = isFFEnabled("new-ppl-bids-page");

    return (
      <Switch location={location}>
        <PrivateRoute path={path.NOT_FOUND}>
          <Suspense fallback={<LoadingOverlay />}>
            <Page404 />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute path={path.CLICKS} campaignSwitcher="ppc">
          <ClickReport />
        </PrivateRoute>
        {!isNewPPLPageEnabled && (
          <PrivatePPLRoute
            path={`${path.PPL}/bids`}
            exact
            campaignSwitcher="ppl"
          >
            <Suspense fallback={<LoadingOverlay />}>
              <RemixPPL
                key={selectedPPLCampaign?.id}
                campaign={selectedPPLCampaign}
                timezone={user.profile?.timeZone}
                permissions={{
                  ppl: permissions?.ppl?.hasAccess || false,
                  ppl_bidding: permissions?.ppl_bidding?.hasAccess || false,
                  ppl_billing: permissions?.ppl_billing?.hasAccess || false,
                  ppl_leads: permissions?.ppl_leads?.hasAccess || false,
                }}
                redirect={history.replace}
              />
            </Suspense>
          </PrivatePPLRoute>
        )}
        <PrivateRoute
          path={[`${path.PPL}/leads`, `${path.PPL}/leads/:leadId`]}
          exact
          campaignSwitcher="ppl"
        >
          <Suspense fallback={<LoadingOverlay />}>
            <RemixPPL
              key={selectedPPLCampaign?.id}
              campaign={selectedPPLCampaign}
              timezone={user.profile?.timeZone}
              permissions={{
                ppl: permissions?.ppl?.hasAccess || false,
                ppl_bidding: permissions?.ppl_bidding?.hasAccess || false,
                ppl_billing: permissions?.ppl_billing?.hasAccess || false,
                ppl_leads: permissions?.ppl_leads?.hasAccess || false,
              }}
              redirect={history.replace}
            />
          </Suspense>
        </PrivateRoute>
        <PrivatePPLRoute path={path.PAYMENT_DETAILS}>
          <Suspense fallback={<LoadingOverlay />}>
            <RenderIf condition={permissions?.ppl_billing?.hasAccess}>
              <PPLPaymentInfo />
            </RenderIf>
          </Suspense>
        </PrivatePPLRoute>
        <PrivatePPLRoute path={path.REFUNDS}>
          <Suspense fallback={<LoadingOverlay />}>
            <RenderIf condition={permissions?.ppl_leads?.hasAccess}>
              <PPLRefunds />
            </RenderIf>
          </Suspense>
        </PrivatePPLRoute>
        <PrivatePPLRoute path={path.PPL}>
          <PPL />
        </PrivatePPLRoute>
        <PrivateRoute path={path.PPC_PAYMENT_DETAILS} campaignSwitcher="ppc">
          <PPCPaymentDetails key={selectedPPCCampaign?.id} />
        </PrivateRoute>
        <PrivateRoute
          fluid
          path={[path.PPC_MANAGE_BIDS]}
          campaignSwitcher="ppc"
        >
          <Suspense fallback={<LoadingOverlay />}>
            <PPC
              key={selectedPPCCampaign?.id}
              selectedProductId={selectedProductId}
            />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute path="/bids/*">
          <Suspense fallback={<LoadingOverlay />}>
            <Redirect to={path.PPC_MANAGE_BIDS} />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute path="/my_profile">
          <MyProfile />
        </PrivateRoute>
        {!user.isLoading ? (
          <Route path="*">
            {user.isLoggedIn ? (
              <Redirect to={path.NOT_FOUND} />
            ) : (
              <Navigate replace to="/login" />
            )}
          </Route>
        ) : null}
      </Switch>
    );
  },
);

import { Route } from "react-router-dom";

const ProtectedRoute = ({
  hasAccess,
  children: Component,
  fallback = null,
  ...rest
}) => <Route {...rest} render={() => (hasAccess ? <Component /> : fallback)} />;

export { ProtectedRoute };

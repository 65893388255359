const fetchExpansionLeads = async () => {
  const response = await fetch("/ppl/api/expansion_leads/list");
  const { expansion_leads, error } = await response.json();

  if (!error) {
    return { expansion_leads };
  }

  return { expansion_leads: [] };
};

export default fetchExpansionLeads;

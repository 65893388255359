import { SET_EXP_LEAD_DETAILS } from "~/pages/PPL/reducers/expansion_leads_reducer";
import setIsLoading from "../set_is_loading";

const fetchLeadDetails = (expansionLeadID) => async (dispatch, getState) => {
  const {
    ppl: { expansionLeads },
  } = getState();

  if (
    Object.keys(expansionLeads).includes(expansionLeadID) &&
    !expansionLeads[expansionLeadID].error
  )
    return;

  try {
    dispatch(setIsLoading(true));

    const response = await fetch(
      `/ppl/api/expansion_leads/fetch?id=${expansionLeadID}`,
    );
    const {
      expansion_lead,
      error,
      status: errorStatus,
    } = await response.json();

    if (!error) {
      dispatch({
        type: SET_EXP_LEAD_DETAILS,
        payload: { expansionLeadID, details: expansion_lead, error: false },
      });
    } else {
      dispatch({
        type: SET_EXP_LEAD_DETAILS,
        payload: { expansionLeadID, details: { error: errorStatus } },
      });
    }

    dispatch(setIsLoading(false));
  } catch (_err) {
    dispatch({
      type: SET_EXP_LEAD_DETAILS,
      payload: {
        expansionLeadID,
        details: {
          error: 500,
        },
      },
    });
  }
};

export default fetchLeadDetails;
